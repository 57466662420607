import React from 'react';
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SettingsIcon from '@mui/icons-material/Settings';
import './Navbar.scss';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Logout } from '@mui/icons-material';

function Navbar() {
  // TODO: color of icons shouldn't be hardcoded
  const navItems = [
    {
      key: '',
      text: 'Dashboard',
      icon: <AppsIcon htmlColor="#ffffff" />,
    },
    {
      key: 'gigs',
      text: 'Gigs',
      icon: <BusinessCenterIcon htmlColor="#ffffff" />,
    },
    {
      key: 'settings',
      text: 'Settings',
      icon: <SettingsIcon htmlColor="#ffffff" />,
    },
  ];

  const { user, logout } = useAuth0();

  return (
    <Box
      className="App-sidebar"
      sx={{
        backgroundColor: 'secondary.dark',
        color: 'secondary.contrastText',
      }}
      display={{ xs: 'none', sm: 'block' }}
    >
      <div className="logo-container">
        <img src="Green_Room_logo_light.png" alt="Green Room" />
      </div>
      <div className="nav-container">
        <div className="nav-list">
          <List sx={{ width: '100%', color: '#ffffff' }}>
            {navItems.map((item) => (
              <ListItemButton key={item.key} component={Link} to={item.key}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            ))}
          </List>
        </div>
        <div className="nav-user">
          <Avatar src={user?.picture} />
          <div>
            <div className="user-name">{user?.name}</div>
            <div className="email">{user?.email}</div>
          </div>
          <div>
            <IconButton aria-label="logout">
              <Logout
                htmlColor="#ffffff"
                onClick={() => logout({ returnTo: window.location.origin })}
              />
            </IconButton>
          </div>
        </div>
      </div>
    </Box>
  );
}

export default Navbar;
