import React from 'react';

function Dashboard() {
  return (
    <>
      <h1>Dashboard</h1>
      <div>Here&apos;s the dashboard!</div>
    </>
  );
}

export default Dashboard;
