import React from 'react';
import {
  Button,
  createTheme,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ThemeProvider,
} from '@mui/material';
import './App.scss';
import { Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Navbar from './Navbar';
import Dashboard from './Dashboard';
import Gigs from './Gigs';
import Settings from './Settings';
import Loading from './Loading';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#006e1f',
        light: '#35b44c',
      },
      secondary: {
        main: '#1a1c19',
        light: '#797c77',
        dark: '#000000',
        contrastText: '#ffffff',
      },
    },
    typography: {
      fontFamily: '"Urbanist"',
    },
  });

  const { isLoading, error, user, isAuthenticated, loginWithRedirect, logout } =
    useAuth0();

  if (error) {
    const open = true;
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Dialog open={open}>
          <DialogTitle>Error</DialogTitle>
          <DialogContent>
            <DialogContentText>{error.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              Try Again
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  }

  React.useEffect(() => {
    if (isAuthenticated) {
      return;
    }
    async function checkUser() {
      if (!isLoading && !user) {
        loginWithRedirect();
      }
    }
    checkUser();
  }, [isLoading]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {isAuthenticated ? (
        <div className="App">
          <Navbar />
          <div className="App-content">
            <Routes>
              <Route path="gigs" element={<Gigs />} />
              <Route path="settings" element={<Settings />} />
              <Route path="/" element={<Dashboard />} />
            </Routes>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </ThemeProvider>
  );
}

export default App;
