import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import './Loading.scss';

function Loading() {
  return (
    <Box display="flex" height="100vh">
      <Box m="auto">
        <CircularProgress size={150} />
      </Box>
    </Box>
  );
}

export default Loading;
