import React from 'react';

function Settings() {
  return (
    <>
      <h1>Settings</h1>
      <div>Change your settings here!</div>
    </>
  );
}

export default Settings;
