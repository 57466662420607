import React from 'react';

function Gigs() {
  return (
    <>
      <h1>Gigs</h1>
      <div>Here&apos;s your gigs!</div>
    </>
  );
}

export default Gigs;
